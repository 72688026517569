import axios from "axios";
import { API_URL } from "utils/constants";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  tunnelViewLogsData: [],
  tunnelLogs: false,
  tunnelLogsState: false,
  loading: false,
  rowsPerPageTunnellogs: 15,
  sort_dict_tunnelLogs: {
    created_at: "desc",
  },
  filter_dict_tunnellogs: {
    status: "active",
  },
};

export const fetchTunnelLogsData = createAsyncThunk("fetchTunnelLogsData", async (id) => {
  // Define the string
  var string = `${localStorage.getItem("username")}:${localStorage.getItem("automation_token")}`;

  // Encode the String
  var encodedString = btoa(string);
  const resp = await axios.get(`${API_URL}/tunnel/tunnel-connection-logs/${id}`, {
    headers: {
      Authorization: `Basic ${encodedString}`,
    },
  });
  return resp;
});

const tunnelLogsSlice = createSlice({
  name: "tunnelLogs",
  initialState,
  reducers: {
    setTunnelLogs: (state, action) => {
      state.tunnelLogs = action.payload;
    },
    setTunnelLogsState: (state, action) => {
      state.tunnelLogsState = action.payload;
    },
    changeRowsPerPageTunnelLogs: (state, action) => {
      state.tunnelViewLogsData = [];
      state.rowsPerPageTunnellogs = action.payload;
    },
    changeSortDictTunnelLogs: (state, action) => {
      state.sort_dict_tunnelLogs = action.payload;
    },
    changeFilter: (state, action) => {
      state.filter_dict_tunnellogs = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchTunnelLogsData.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchTunnelLogsData.fulfilled, (state, action) => {
      state.tunnelViewLogsData = action.payload.data.data;
      state.loading = "fulfilled";
    });
    builder.addCase(fetchTunnelLogsData.rejected, (state, action) => {
    });
  },
});

export const {
  setTunnelLogs,
  setTunnelLogsState,
  changeSortDictTunnelLogs,
  changeRowsPerPageTunnelLogs,
} = tunnelLogsSlice.actions;
export default tunnelLogsSlice.reducer;
