import React, { useState } from 'react'
import BasicLayout from '../components/BasicLayout';
import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import bgImage from "assets/images/cloudify/background.jpg"
import MDButton from 'components/MDButton';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/appSlice';
import { signout } from 'store/appSlice';
import { AUTHURL } from 'utils/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [credentials , setCredentials] = useState({
      currentPassword : "",
      newPassword:"",
      confirmNewPassword:"",
    })
  const [errors, setErrors] = useState({});
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; 
  const changeCredentials =(e)=>{
    // const {value,name} = event.target;
    setCredentials({
      ...credentials,
      [e.target.id]:e.target.value 
    })
    setErrors({ ...errors, [e.target.id]: "" });
  }
  const validateForm = () => {
    let formErrors = {};
    if (!credentials.currentPassword) {
      formErrors.currentPassword = "Current password is required";
    }
    else if (!regex.test(credentials.currentPassword)) {
      formErrors.currentPassword =
        "Password must be at least 8 characters";
    }
    if (!credentials.newPassword) {
      formErrors.newPassword = "New password is required";
    } 
    else if (!regex.test(credentials.newPassword)) {
      formErrors.newPassword =
        "Password must be at least 8 characters";
    }
    if (!credentials.confirmNewPassword) {
      formErrors.confirmNewPassword = "Please confirm your new password";
    } 
    else if (!regex.test(credentials.newPassword)) {
      formErrors.newPassword =
        "Password must be at least 8 characters";
    }else if (credentials.newPassword !== credentials.confirmNewPassword) {
      formErrors.confirmNewPassword = "Passwords do not match";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const updatePassword = async () => {
    let email = localStorage.getItem("email");
    if (!validateForm()) return;
    try {
      setLoading(true);
      let newCredsData = {
        current_password: credentials.currentPassword,
        password: credentials.newPassword,
        confirm_password: credentials.confirmNewPassword,
        email : email
      };
      
      const resp = await axios.post(`${AUTHURL}/user/reset-password`, newCredsData);
      let info = resp.data.data;
      dispatch(
        setSnackbar({
          open: true,
          type: "success",
          title: "Successfully changed password",
          content: "Please login with your new password",
        })
      );
      localStorage.removeItem("accessToken");
      navigate("/sign-in", { replace: true });
    } catch (error) {
      dispatch(
        setSnackbar({
          open: true,
          type: "warning",
          title:   error?.response?.statusText,
          content: error.response.data ? error.response.data.message : error.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-4}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white">
        Create new password
        </MDTypography>
      </MDBox>
      <MDBox py={8} px={3}>
        <MDBox component="form" role="form">

        <MDBox mb={2}>
            <MDInput
              type="password"
              label="Current password"
              id="currentPassword"
              fullWidth
              value={credentials.currentPassword}
              onChange={changeCredentials}
            />
            {errors.currentPassword && ( 
              <MDTypography
                variant="caption"
                style={{
                  color: "red",
                  fontWeight: "400",
                  marginTop: "5px",
                  fontSize: "12px",
                  display: "inline",
                }}
              >
                 {errors.currentPassword} 
              </MDTypography>
            )} 
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="New password"
              id="newPassword"
              fullWidth
              value={credentials.newPassword}
              onChange={changeCredentials}
            />
             {errors.newPassword && ( 
              <MDTypography
                variant="caption"
                style={{
                  color: "red",
                  fontWeight: "400",
                  marginTop: "5px",
                  fontSize: "12px",
                  display: "inline",
                }}
              >
                 {errors.newPassword} 
              </MDTypography>
             )}
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Confirm new password"
              id="confirmNewPassword"
              fullWidth
              value={credentials.confirmNewPassword}
              onChange={changeCredentials}
            //   onKeyDown={passwordEnteredFunction}
            />
              {errors.confirmNewPassword && ( 
              <MDTypography
                variant="caption"
                style={{
                  color: "red",
                  fontWeight: "400",
                  marginTop: "5px",
                  fontSize: "12px",
                  display: "inline",
                }}
              >
                 {errors.confirmNewPassword} 
              </MDTypography>
             )} 

          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              disabled={loading}
              onClick={updatePassword}
            >
              {loading ? "loading..." : "Update Password"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  </BasicLayout>
);
}

export default UpdatePassword