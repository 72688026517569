import moment from "moment";
import { API_URL } from "utils/constants";
import axios from "../utils/axios-interceptor";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  startDate: String(moment().subtract(6, "days")._d),
  endDate: String(new Date()),
  graphData: {
    labels: [""],
    datasets: [
      {
        label: "Completed",
        data: [null],
        backgroundColor: "rgb(93, 138, 168)",
        borderRadius: 5,
        skipNull: true,
      },
      {
        label: "Aborted",
        data: [null],
        backgroundColor: "rgb(100, 162, 187)",
        borderRadius: 5,
        skipNull: true,
      },
      {
        label: "Error",
        data: [null],
        backgroundColor: "#ADD8E6",
        borderRadius: 5,
        skipNull: true,
      },
      {
        label: "Timeout",
        data: [null],
        backgroundColor: "rgb(154,201,227)",
        borderRadius: 5,
        skipNull: true,
      },
      {
        label: "IdleTimeout",
        data: [null],
        backgroundColor: "#AFEEEE",
        borderRadius: 5,
        skipNull: true,
      },
    ],
  },
  key: "selection",
  create_org_date: "",
};

export const getOrgData = createAsyncThunk("getOrgData", async (_, { dispatch }) => {
  try {
    const response = await axios.get(`${API_URL}/get-org-data`);
    dispatch(setCreateOrgDate(response.data.data.created_at));
    return response;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    throw error;
  }
});

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    changeDashboardDate: (state, action) => {
      state.startDate = String(action.payload[0].startDate);
      state.endDate = String(action.payload[0].endDate);
    },
    setCreateOrgDate: (state, action) => {
      state.create_org_date = action.payload;
    },
    changeGraphData: (state, action) => {
      state.graphData = action.payload;
    },
  },
});

export const graphDataState = (state) => state.dashboard.graphData;
export const { changeDashboardDate, setCreateOrgDate, changeGraphData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
