import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "utils/axios-interceptor";
import { BROWSER_API } from "utils/constants";
import { API_URL } from "utils/constants";
import { filterUpdatedList } from "utils/helper";

const initialState = {
  loading: false,
  payload: {
    page_size: 15,
    page_no: 1,
    filter_dict: {
      status: ["aborted", "completed", "error", "timeout", "IdleTimeout"],
    },
    sort_dict: {
      created_at: "desc",
    },
  },
  selectedBrowserFilterVersions: [],
  build_data: [],
  total_records: 0,
};

export const fetchBuildsAsync = createAsyncThunk("build-report/fetchRecords", async (data) => {
  let payload = { ...data };
  if (!payload.filter_dict.status) {
    payload.filter_dict.status = ["aborted", "completed", "error", "timeout", "IdleTimeout"];
  }
  if (data?.search && data?.search !== "") {
    payload["search"] = data.search;
  } else {
    delete payload["search"];
  }

  const response = await axios.post(`${API_URL}//webdriver_session/sessions/live/get-builds/`, {
    ...payload,
  });

  return {
    records: response.data.data.records,
    total_records_test: response.data.data.total_records,
    enable_delete: response.data.data.enable_delete,
    payloadData: { ...data },
  };

  // const response = await axios.post(`${API_URL}/get-builds`, { ...payload });

  // return {
  //   records: response.data.data.records,
  //   total_records_test: response.data.data.total_records,
  //   enable_delete: response.data.data.enable_delete,
  //   payloadData: { ...data },
  // };
});

export const fetchBrowserVersions = createAsyncThunk("build-report/browserVersions", async () => {
  const response = await axios.get(`${BROWSER_API}/test.json`);
  return response.data;
});

export const fetchTodos = createAsyncThunk("fetchTodos", async (data, thunkAPI) => {
  try {
    let payload = {
      page_size: data.page_size,
      page_no: data.page_no,
      filter_dict: data.filter_dict,
      sort_dict: data.sort_dict,
    };

    if (data?.search && data?.search !== "") payload["search"] = data.search;
    const resp = await axios.post(`${API_URL}/webdriver_session/sessions/history/get-records`, {
      ...payload,
    });

    thunkAPI.dispatch(setEnableDelete(resp?.data?.data?.enable_delete));

    return resp;
  } catch (error) {
    console.error("Error fetching todos:", error);
    throw error;
  }
});

const buildSlice = createSlice({
  name: "buildSlice",
  initialState,
  reducers: {
    setBrowserFilterList: (state, action) => {
      let browserList = current(state.browserFilterList);
      let updatedBrowserList = action.payload.reduce((accumulator, item) => {
        return (accumulator = [...accumulator, ...browserList[item]]);
      }, []);
      let updatedArray = Array.from(new Set(updatedBrowserList));
      state.selectedBrowserFilterVersions = updatedArray;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBuildsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchBuildsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.build_data = action.payload.records;
      state.payload = action.payload.payloadData;
      state.total_records = action.payload.total_records_test;
    });
    builder.addCase(fetchBuildsAsync.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.loading = false;
    });
    builder.addCase(fetchBrowserVersions.fulfilled, (state, action) => {
      state.browserFilterList = action.payload;
      if (JSON.parse(sessionStorage.getItem("filter_dict_reports"))) {
        let filterData = JSON.parse(sessionStorage.getItem("filter_dict_reports"));
        let filter_dict = filterUpdatedList(filterData);
        if (filter_dict.browser_name) {
          let browserList = action.payload;
          let updatedBrowserList = filter_dict.browser_name.reduce((accumulator, item) => {
            return (accumulator = [...accumulator, ...browserList[item]]);
          }, []);
          let updatedArray = Array.from(new Set(updatedBrowserList));
          state.selectedBrowserFilterVersions = updatedArray;
        }
      }
    });
    builder.addCase(fetchTodos.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTodos.fulfilled, (state, action) => {
      state.loading = false;
      state.sessions = action.payload.data.data.records;
      state.count = action.payload.data.data.total_records;
    });
    builder.addCase(fetchTodos.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setBrowserFilterList } = buildSlice.actions;
export const build_loading = (state) => state.buildSlice.loading;
export const build_payload = (state) => state.buildSlice.payload;
export const build_data = (state) => state.buildSlice.build_data;
export const build_total_records = (state) => state.buildSlice.total_records;
export const hs_selected_browser_versions = (state) =>
  state.buildSlice.selectedBrowserFilterVersions;

export default buildSlice.reducer;
