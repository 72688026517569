/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/cloudify/background.jpg";
import OTPInput from "react-otp-input";
import { setSnackbar } from "store/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { AUTHURL } from "utils/constants";
import { DOMAIN } from "utils/constants";
import jwt_decode from "jwt-decode";

function OtpVerification() {

  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = localStorage.getItem("accessToken");
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
    
  let decoded;
  try {
  if(!isAuthenticated){
    navigate("/sign-in")
  }
  if (token !== null) decoded = jwt_decode(token);
  } catch (error) {
    console.error("Failed to decode token", error);
  }
  let latestDate = new Date();

  const setCookies = (cookieName, cookieValue) => {
    document.cookie = `${cookieName}=${cookieValue};domain=${DOMAIN};path=/`;
  };

  const verifyOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${AUTHURL}//user/verify-login-otp`, {
        otp
      });
      if (response.status === 200) {
        let info = response?.data?.data;
           
        localStorage.setItem("accessToken", info.jwt);   
        localStorage.setItem("refreshToken", info.refresh_token);
        localStorage.setItem("username", info.username);
        localStorage.setItem("role", info.role);
        localStorage.setItem("automation_token", info.automation_token);
        localStorage.setItem("user_id", info.user_id);
        localStorage.setItem("email",info.email);
        localStorage.setItem("total_parallel_sessions", info.total_parallel_sessions);
        setCookies("accessToken", info.jwt);
        setCookies("refreshToken", info.refresh_token);
        setCookies("username", info.username);
        setCookies("role", info.role);
        setCookies("automation_token", info.automation_token);
        setCookies("user_id", info.user_id);
        setCookies("total_parallel_sessions", info.total_parallel_sessions);
        dispatch(
          setSnackbar({
            open: true,
            type: "success",
            title: response?.statusText,
            content: response?.data?.message ,
          })
        ); 
        navigate("/", { replace: true });
      }
    } catch (err) {
      dispatch(
        setSnackbar({
          open: true,
          type: "warning",
          title: err.response?.statusText,
          content: err.response?.data?.message ,
        })
      ); 
    } finally {
      setLoading(false);
    }
  };
  if (decoded && decoded.exp * 1000 > latestDate.getTime()) return <Navigate to={"/"} />;
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Enter verification code
          </MDTypography>
        </MDBox>
        <MDBox py={8} px={3}>
          <MDBox component="form" role="form">
            <MDBox 
            mb={2} sx={{ display: "flex", justifyContent: "center" }} 
            >
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>&nbsp;-&nbsp;</span>}
                renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "40px",
                        height: "40px",
                        textAlign: "center",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                      }}
                    />
                  )}
              />
              </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={loading || otp.length !== 4}
                onClick={verifyOtp }
              >
                {loading ? "loading..." : "Verify code"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}
export default OtpVerification