import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "utils/axios-interceptor";
import { BROWSER_API } from "utils/constants";
import { API_URL } from "utils/constants";
import { filterUpdatedList } from "utils/helper";

const initialState = {
  sessions: [],
  loading: false,
  isError: false,
  rowsPerPage: 15,
  count: 0,
  pageNumber: 1,
  sort_dict: {
    created_at: "desc",
  },
  search: "",
  filter_dict: {
    status: ["timeout", "completed", "error", "aborted", "IdleTimeout"],
  },
  resetFilterHistorySessions: false,
  browsers: [],
  selectedBrowserVersions: [],
  enableDelete: false,

  // devdata
  browserFilterList: [],
  selectedBrowserFilterVersions: [],
  history_test_loading: false,
  page_no_test: 1,
  page_size_test: 15,
  sort_dict_test: { created_at: "desc" },
  payload_test: {
    page_size: 15,
    page_no: 1,
    filter_dict: {
      status: ["aborted", "completed", "error", "timeout", "IdleTimeout"],
    },
    sort_dict: {
      created_at: "desc",
    },
  },
  history_data_test: [],
  total_records_test: 0,
  enable_delete: false,
};

export const fetchRecordsAsync = createAsyncThunk("history-sessions/fetchRecords", async (data) => {
  let payload = { ...data };
  if (!payload.filter_dict.status) {
    payload.filter_dict.status = ["aborted", "completed", "error", "timeout", "IdleTimeout"];
  }
  if (data?.search && data?.search !== "") {
    payload["search"] = data.search;
  } else {
    delete payload["search"];
  }
  const response = await axios.post(`${API_URL}/webdriver_session/sessions/history/get-records`, {
    ...payload,
  });

  return {
    records: response.data.data.records,
    total_records_test: response.data.data.total_records,
    enable_delete: response.data.data.enable_delete,
    payloadData: { ...data },
  };

  // const response = await axios.post(`${API_URL}/records`, { ...payload });
  // console.log(response.data);

  // return {
  //   records: response.data.data.records,
  //   total_records_test: response.data.data.total_records,
  //   enable_delete: response.data.data.enable_delete,
  //   payloadData: { ...data },
  // };
});

export const fetchBrowserVersions = createAsyncThunk(
  "history-sessions/browserVersions",
  async () => {
    const response = await axios.get(`${BROWSER_API}/test.json`);
    return response.data;
  }
);

export const fetchTodos = createAsyncThunk("fetchTodos", async (data, thunkAPI) => {
  try {
    let payload = {
      page_size: data.page_size,
      page_no: data.page_no,
      filter_dict: data.filter_dict,
      sort_dict: data.sort_dict,
    };

    if (data?.search && data?.search !== "") payload["search"] = data.search;
    const resp = await axios.post(`${API_URL}/webdriver_session/sessions/history/get-records`, {
      ...payload,
    });

    thunkAPI.dispatch(setEnableDelete(resp?.data?.data?.enable_delete));

    return resp;
  } catch (error) {
    console.error("Error fetching todos:", error);
    throw error;
  }
});

const historySessionSlice = createSlice({
  name: "historySession",
  initialState,
  reducers: {
    changeRowsPerPage: (state, action) => {
      state.sessions = [];
      state.rowsPerPage = action.payload;
    },
    changePageNumber: (state, action) => {
      state.sessions = [];
      state.pageNumber = action.payload;
    },
    changeSortDict: (state, action) => {
      state.sort_dict = action.payload;
    },
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
    changeFilter: (state, action) => {
      state.filter_dict = action.payload;
    },
    changeLoading: (state, action) => {
      state.loading = action.payload;
    },
    removeSessions: (state) => {
      state.sessions = [];
    },
    setResetFilterHistorySessions: (state, action) => {
      state.resetFilterHistorySessions = action.payload;
    },
    setEnableDelete: (state, action) => {
      state.enableDelete = action.payload;
    },
    setBrowsers: (state, action) => {
      state.browsers = action.payload;
    },
    setBrowserFilterList: (state, action) => {
      // let browserList = current(state.browserFilterList);
      // let updatedBrowserList = action.payload.reduce((accumulator, item) => {
      //   return (accumulator = [...accumulator, ...browserList[item]]);
      // }, []);
      // let updatedArray = Array.from(new Set(updatedBrowserList));
      // state.selectedBrowserFilterVersions = updatedArray;
    },
    updateSelectedBrowserVersionsHs: (state, action) => {
      let page = "history-sessions";
      if (sessionStorage.getItem(page) !== null) {
        let data = [];
        let localSessionData = JSON.parse(sessionStorage.getItem(page));
        if (localSessionData.filters.browser) {
          localSessionData.filters.browser.map((li) => {
            state.browsers[li].map((version) => {
              data.push(version);
            });
          });
        }
        let comparisonData = [];
        comparisonData = data;
        const filteredComaprisonData = comparisonData.filter(function (item, i, ar) {
          return ar.indexOf(item) === i;
        });
        // return filteredComaprisonData;
        state.selectedBrowserVersions = filteredComaprisonData;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecordsAsync.pending, (state, action) => {
      state.loading = true;
      state.history_test_loading = true;
    });
    builder.addCase(fetchRecordsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.history_test_loading = false;
      state.history_data_test = action.payload.records;
      state.payload_test = action.payload.payloadData;
      state.enable_delete = action.payload.enable_delete;
      state.total_records_test = action.payload.total_records_test;
    });
    builder.addCase(fetchRecordsAsync.rejected, (state, action) => {
      state.loading = false;
      state.history_test_loading = false;
    });
    builder.addCase(fetchBrowserVersions.fulfilled, (state, action) => {
      state.browserFilterList = action.payload;
      if (JSON.parse(sessionStorage.getItem("filter_dict_history"))) {
        let filterData = JSON.parse(sessionStorage.getItem("filter_dict_history"));
        let filter_dict = filterUpdatedList(filterData);
        if (filter_dict.browser_name) {
          let browserList = action.payload;
          let updatedBrowserList = filter_dict.browser_name.reduce((accumulator, item) => {
            return (accumulator = [...accumulator, ...browserList[item]]);
          }, []);
          let updatedArray = Array.from(new Set(updatedBrowserList));
          state.selectedBrowserFilterVersions = updatedArray;
        }
      }
    });
    builder.addCase(fetchTodos.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTodos.fulfilled, (state, action) => {
      state.loading = false;
      state.sessions = action.payload.data.data.records;
      state.count = action.payload.data.data.total_records;
    });
    builder.addCase(fetchTodos.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.loading = false;
    });
  },
});

export const {
  changeRowsPerPage,
  changePageNumber,
  changeLoading,
  removeSessions,
  changeSortDict,
  changeSearch,
  changeFilter,
  setResetFilterHistorySessions,
  setBrowsers,
  setEnableDelete,
  updateSelectedBrowserVersionsHs,
  setBrowserFilterList,
} = historySessionSlice.actions;

export const hs_data_test = (state) => state.historySession.history_data_test;
export const hs_loading_test = (state) => state.historySession.history_test_loading;
export const hs_payload_test = (state) => state.historySession.payload_test;
export const hs_total_test = (state) => state.historySession.total_records_test;
export const enable_delete = (state) => state.historySession.enable_delete;
export const hs_browser_filter_list = (state) => state.historySession.browserFilterList;
export const hs_selected_browser_versions = (state) =>
  state.historySession.selectedBrowserFilterVersions;

export default historySessionSlice.reducer;
