import { createSlice } from "@reduxjs/toolkit";
import { deleteAllCookies } from "utils/helper";

const initialState = {
  isAuthenticated: false, 
  notification: {
    open: false,
    type: "success",
    title: "",
    content: "",
  },
  sessionsCount: {
    total: 0,
    running: 0,
    pending: 0,
    loading: false,
  },
  dark: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.notification = action.payload;
    },
    updateSessionsCount: (state, action) => {
      state.sessionsCount.total = action.payload.total;
      state.sessionsCount.running = action.payload.running;
      state.sessionsCount.pending = action.payload.pending;
    },
    setSessionsCountLoading: (state, action) => {
      state.sessionsCount.loading = action.payload;
    },
    setDark: (state, action) => {
      state.dark = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    signout: (state) => {
      localStorage.clear();
      deleteAllCookies();
      state.isAuthenticated = false;

    },
  },
  
});

export const { setSnackbar, updateSessionsCount, setSessionsCountLoading, setDark, signout, setAuthenticated} =
  appSlice.actions;
export default appSlice.reducer;
