import moment from "moment";
import MDAvatar from "components/MDAvatar";
import {
  chrome,
  firefox,
  edge,
  safari,
  opera,
  appium,
  android,
  FLAVOUR2,
  DOMAIN,
  COGNITO_LOGIN_URL,
  cypress,
  uncorrect_icon,
} from "./constants";
import { Chip } from "@mui/material";
import MDTypography from "components/MDTypography";
import BuildSearchDropdown from "components/MuiTable/Component/BuildSearchDropdown";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MuiTooltip from "components/MuiTooltip/MuiTooltip";
import { createTheme } from "@mui/material/styles";

export const changeDateFormat = (date) => {
  return moment(new Date(date * 1000).toGMTString()).format("YYYY MMM DD hh:mm:ss a");
};
export const changeDateFormatTunnel = (date) => {
  return moment(date).format("YYYY MMM DD hh:mm:ss");
};

export const duration = (date, page) => {
  const value =  moment(new Date(date * 1000).toGMTString()).format("YYYY MMM DD hh:mm:ss a");
  if (page === "sessions" ) {
    const startTime = moment.utc(date * 1000);
    const endTime = moment.utc();
    const duration = moment.duration(endTime.diff(startTime));
    const hrs = String(duration.hours()).padStart(2, '0');
    const min = String(duration.minutes()).padStart(2, '0');
    const sec = String(duration.seconds()).padStart(2, '0');

    return `${hrs}h ${min}m ${sec}s`;
    // var value = moment(new Date(date * 1000).toGMTString()).format("HH:mm:ss A");
    // var startTime = moment(value, "HH:mm:ss A").subtract(1, "second");
    // var endTime = moment(new Date());
    // var hrs = moment.utc(endTime.diff(startTime)).format("HH");
    // var min = moment.utc(endTime.diff(startTime)).format("mm");
    // var sec = moment.utc(endTime.diff(startTime)).format("ss");
    // return `${hrs}h ${min}m ${sec}s`;
  } else {
    let totalDuration = moment
      .utc(date * 1000)
      .format("HH:mm:ss")
      .split(":");
    return `${totalDuration[0]}h ${totalDuration[1]}m ${totalDuration[2]}s`;
  }
};
export const Img = ({ browser }) => {
  return (
    <img
      src={
        browser === "edge"
          ? edge
          : browser === "chrome"
          ? chrome
          : browser === "opera"
          ? opera
          : browser === "safari"
          ? safari
          : browser === "firefox"
          ? firefox
          : browser === "appium"
          ? appium
          : browser === "cypress"
          ? cypress
          : browser === "android"
          ? android
          : uncorrect_icon
      }
      alt={browser}
      style={{ height: "1.5rem", width: "1.5rem" }}
    />
  );
};

export const ImgContainer = ({ browser }) => {
  return (
    <MDAvatar
      src={
        browser === "edge"
          ? edge
          : browser === "chrome"
          ? chrome
          : browser === "opera"
          ? opera
          : browser === "safari"
          ? safari
          : browser === "firefox"
          ? firefox
          : browser === "appium"
          ? appium
          : browser === "cypress"
          ? cypress
          : browser === "android"
          ? android
          : uncorrect_icon
      }
      size="xs"
    />
  );
};

export const displayCoulmnFun = (page, column, value) => {
  // if (value) {
  //   return value;
  // }
  return sessionStorage.getItem(page) === null ||
    JSON.parse(sessionStorage.getItem(page))["columns"] === undefined ||
    JSON.parse(sessionStorage.getItem(page))["columns"][column] === undefined
    ? value
    : JSON.parse(sessionStorage.getItem(page))?.["columns"]?.[column];
  // let booleanValue = JSON.parse(sessionStorage.getItem(page))["columns"][column];
  // console.log(booleanValue);
  // return booleanValue;
};

export const ChipComp = ({ status }) => {
  const chipLabel = status === null ? "null" : status?.toString();
  return (
    <Chip
      label={chipLabel}
      size="small"
      sx={{
        width: "5.4rem",
        background: `var(--${chipLabel})`,
        color: "white !important",
        fontWeight: "400",
        textTransform: "capitalize",
      }}
    />
  );
};

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const changeFilterData = (data) => {
  let filterList = data;
  var obj = filterList.reduce(function (acc, cur, i) {
    if (cur !== []) acc[i] = cur;
    return acc;
  }, {});
  obj = Object.entries(obj).reduce((a, [k, v]) => (v.length == 0 ? a : ((a[k] = v), a)), {});
  var str = JSON.stringify(obj);

  str = str.replace(/"0"/g, `"id"`);
  str = str.replace(/"1"/g, `"browser_name"`);
  str = str.replace(/"2"/g, `"status"`);
  str = str.replace(/"3"/g, `"status"`);
  str = str.replace(/"4"/g, `"enable_video"`);
  str = str.replace(/"5"/g, `"enable_logs"`);
  str = str.replace(/"7"/g, `"screen_resolution"`);
  str = str.replace(/"9"/g, `"build_name"`);
  str = str.replace(/"12"/g, `"session_automation_type"`);
  str = str.replace(/"13"/g, `"user_name"`);
  str = str.replace(/"15"/g, `"browser_version"`);
  str = str.replaceAll(`["Enable"]`, `true`);
  str = str.replaceAll(`["Disable"]`, `false`);
  return JSON.parse(str);
};

export const filterListData = (page, column) => {
  return sessionStorage.getItem(page) === null ||
    JSON.parse(sessionStorage.getItem(page))["filters"][column] === undefined
    ? []
    : JSON.parse(sessionStorage.getItem(page))["filters"][column];
};

export const filterListUpdated = (page, column) => {
  return (
    sessionStorage.getItem(page) !== null &&
    JSON.parse(sessionStorage.getItem(page))[column] !== undefined &&
    JSON.parse(sessionStorage.getItem(page))[column]
  );
};

export const filterUpdatedList = (filterList) => {
  let filterObject = Object.assign({}, filterList);
  Object.keys(filterObject).forEach((key) => {
    if (updatedFilterKeys[key] && filterObject[key].length > 0) {
      filterObject[updatedFilterKeys[key]] = filterObject[key];
      delete filterObject[key];
    } else {
      delete filterObject[key];
    }
  });
  return filterObject;
};

export const updatedFilterKeys = {
  1: "browser_name",
  3: "status",
  4: "enable_video",
  5: "enable_logs",
  7: "screen_resolution",
  9: "build_name",
  10: "test_status",
  13: "session_automation_type",
  14: "user_name",
  16: "browser_version",
};

export const filter_dict_hs = () => {
  if (
    sessionStorage.getItem("history-sessions") !== null &&
    JSON.parse(sessionStorage.getItem("history-sessions"))["filters"]
  ) {
    let data = JSON.parse(sessionStorage.getItem("history-sessions"))["filters"];
    // let updatedData = { ...data };
    // if (data["browser"]) {
    //   updatedData["browser_name"] = data["browser"];
    //   delete updatedData["browser"];
    // }
    // if (updatedData["enable_logs"]) {
    //   updatedData["enable_logs"][0] === "Disable"
    //     ? (updatedData["enable_logs"] = false)
    //     : (updatedData["enable_logs"] = true);
    // }
    // if (updatedData["enable_video"]) {
    //   updatedData["enable_video"][0] === "Disable"
    //     ? (updatedData["enable_video"] = false)
    //     : (updatedData["enable_video"] = true);
    // }

    // console.log(updatedData);

    let updatedData = { ...data };
    for (const key in data) {
      if (key !== "status" && data[key].length === 0) {
        delete updatedData[key];
      }
      if (key === "browser" && data[key].length !== 0) {
        updatedData["browser_name"] = data["browser"];
        delete updatedData["browser"];
      }
      if ((key === "enable_logs" || key === "enable_video") && data[key].length !== 0) {
        updatedData[key][0] === "Disable" ? (updatedData[key] = false) : (updatedData[key] = true);
      }
      if (key === "status" && data[key].length === 0)
        updatedData[key] = ["aborted", "completed", "error", "timeout", "IdleTimeout"];
    }
    if (!updatedData["status"]) {
      updatedData = {
        ...updatedData,
        status: ["aborted", "completed", "error", "timeout", "IdleTimeout"],
      };
    }
    if (Object.keys(updatedData).length === 0)
      updatedData = { status: ["aborted", "completed", "error", "timeout", "IdleTimeout"] };
    return updatedData;
  } else return { status: ["aborted", "completed", "error", "timeout", "IdleTimeout"] };
};

export const searchTextBuildName = (text, searchText) =>
  text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export const customColumnTextName = (value) => {
  let name;
  if (value && value.length > 30) {
    name = `${value.slice(0, 30)}...`;
  } else {
    name = value;
  }
  return (
    <MuiTooltip
      title={value}
      arrow
      placement="top"
      content={
        <MDTypography
          variant="button"
          fontWeight="medium"
          lineHeight={1}
          className="non-empty-cell"
        >
          {name === null ? "null" : name}
        </MDTypography>
      }
    />
  );
};

export const filterBuildNameDropDown = (filterList, onChange, index, column, data, page) => {
  return (
    <BuildSearchDropdown
      filterList={filterList}
      index={index}
      onChange={(e) => onChange(filterList[index], index, column)}
      column={column}
      page={page}
      data={data}
    />
  );
};

export const hostname = window.location.hostname.replace(`.${DOMAIN}`, "");

export const deleteAllCookies = () => {
  let c = document.cookie.split(";");
  for (const k of c) {
    let s = k.split("=");
    document.cookie = s[0].trim() + `=;expires=${new Date(0).toUTCString()}`;
  }
};

// get cookie data
export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

// User will logout from all tabs
function storageChange(event) {
  if (
    event.key === null ||
    (event.key === "accessToken" && (event.newValue === null || event.newValue === ""))
  ) {
    // window.location.href = COGNITO_LOGIN_URL;
    window.location.href = "/sign-in";
  }
}

// check local storage
export const checkLocalStorage = () => {
  if (FLAVOUR2 === "false") {
    document.title = hostname;
    var accessToken = localStorage.getItem("accessToken");
    var refreshToken = localStorage.getItem("refreshToken");
    var name = localStorage.getItem("name");
    var orgId = localStorage.getItem("orgId");
    var user_id = localStorage.getItem("user_id");
    var org = localStorage.getItem("org");

    if (!accessToken || !refreshToken || !name || !orgId || !user_id || !org) {
      localStorage.setItem("accessToken", getCookie("accessToken"));
      localStorage.setItem("refreshToken", getCookie("refreshToken"));
      localStorage.setItem("name", getCookie("name"));
      localStorage.setItem("orgId", getCookie("orgId"));
      localStorage.setItem("user_id", getCookie("user_id"));
      localStorage.setItem("org", getCookie("org"));
    } else {
      console.log("true authentication");
      // localStorage.setItem("accessToken", getCookie("accessToken"));
      // localStorage.setItem("refreshToken", getCookie("refreshToken"));
      // localStorage.setItem("name", getCookie("name"));
      // localStorage.setItem("orgId", getCookie("orgId"));
      // localStorage.setItem("user_id", getCookie("user_id"));
      // localStorage.setItem("org", getCookie("org"));
    }
    window.addEventListener("storage", storageChange);
    return () => {
      window.removeEventListener("storage", storageChange);
    };
  }
};

export const getMuiTheme = (dark) =>
  createTheme({
    palette: dark && {
      mode: "dark",
      background: {
        default: "#202940",
        paper: "#202940",
      },
    },
    components: {
      MuiPaper: dark
        ? {
            styleOverrides: {
              root: {
                background: "#202940",
              },
              elevation2: {
                right: "0 !important",
                left: "unset !important",
              },
            },
          }
        : {
            styleOverrides: {
              elevation2: {
                right: "0 !important",
                left: "unset !important",
              },
            },
          },
      MuiList: {
        styleOverrides: {
          root: {
            maxHeight: "350px",
            textTransform: "capitalize",
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            display: "flex",
            color: "white",
            background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
            borderRadius: "0.75rem",
            position: "relative",
            width: "97%",
            top: "-25px",
            alignSelf: "center",
            padding: "17px 8px",
            boxShadow:
              "0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%)",
          },
          icon: {
            color: "white",
          },
          filterPaper: {
            width: "450px",
            borderRadius: "0.75rem",
            marginLeft: "-0.5rem",
          },
          actions: {
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            // flex: "0 !important",
          },
          iconActive: {
            background: "#2279e9",
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            height: `calc(100vh - 8.39rem)`,
            borderRadius: "0.75rem",
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
          },
          liveAnnounce: {
            height: "0px !important",
          },
          responsiveBase: {
            flex: 1,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "#7b809a",
            borderRadius: "none",
            boxShadow: "none",
            fontSize: "0.7rem",
            fontWeight: 700,
            textTransform: "capitalize",
            textWrap: "nowrap",
          },
          footer: {
            border: 0,
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            color: "#7b809a",
          },
        },
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            flex: "0 !important",
            color: "white",
            background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
            borderRadius: "0.75rem",
            position: "relative",
            width: "97%",
            top: "-25px",
            alignSelf: "center",
            padding: "17px 8px",
            boxShadow:
              "0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%)",
          },
        },
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            padding: "16px 24px 24px 24px",
          },
          title: {
            marginLeft: "0px",
          },
          resetLink: {
            display: "inline-block",
            marginLeft: "7px",
            fontSize: "14px",
            fontweight: "500",
            position: "absolute",
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            marginTop: "-47px",
            "& > .MuiGrid-item": {
              paddingTop: "20px",
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: "0.75rem",
            marginRight: "1.5rem",
          },
        },
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          title: {
            display: "inline-block",
            marginLeft: "7px",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "14px",
            fontWeight: "500",
            marginLeft: "0px",
            textTransform: "uppercase",
            letterSpacing: "0.01071em",
            lineHeight: "1.43",
            fontFamily: "Roboto",
          },
          label: {
            color: "rgba(0, 0, 0, 0.6)",
            fontFamily: "sans-serif",
            fontWeight: "400",
            fontSize: "1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            marginLeft: "2px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          label: {
            overflow: "inherit",
            textTransform: "capitalize",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "12px",
            padding: "5px 20px",
            fontWeight: "bold",
            background: dark ? "white" : "linear-gradient(195deg, #42424a, #191919)",
            color: dark ? "black" : "white",
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          select: {
            color: "white",
            paddingTop: "0.5rem",
          },
          selectLabel: {
            color: "#344767",
          },
          selectIcon: {
            color: "white",
            top: "calc(50% - 0.48em)",
            marginRight: "2px",
          },
        },
      },
    },
  });
