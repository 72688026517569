const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  browsers: {},
  devices: {},
  manualTimeout: 10,
  idleTimeout: 10,
  test_name: "test name",
  build_name: "build name",
  selectedBrowserVersion: "",
  selectedSeleniumVersion: "",
  selectedDeviceName: "Desktop",
  selectedBrowser: "chrome",
  selectedScreenResolution: "1920x1080x24",
  languages: ["Java", "Python", "Ruby", "Curl"],
  selectedLanguage: "Java",
  saveVideo: false,
  saveLogs: false,
  selectedAutoVersionData: "Selenium 3",
  proxy: false,
  tunnelName: "",
  cypressModal: false,
};

const capabilitiesSlice = createSlice({
  name: "capabilities",
  initialState,
  reducers: {
    setBrowsers: (state, action) => {
      state.browsers = action.payload;
    },
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    setInputData: (state, action) => {
      state[action.payload.id] = action.payload.value;
    },
    setLogs: (state, action) => {
      state.saveLogs = action.payload;
    },
    setVideo: (state, action) => {
      state.saveVideo = action.payload;
    },
    setProxy: (state, action) => {
      state.proxy = action.payload;
    },
    setSelectedData: (state, action) => {
      const {
        browser_name,
        browser_version,
        enable_logs,
        enable_video,
        idle_session_timeout,
        screen_resolution,
        session_timeout,
      } = action.payload;
      state.selectedBrowser = browser_name;
      state.selectedBrowserVersion = browser_version;
      state.saveLogs = enable_logs;
      state.saveVideo = enable_video;
      state.manualTimeout = session_timeout;
      state.idleTimeout = idle_session_timeout;
      state.selectedScreenResolution = screen_resolution;
    },
    toggleCypressModal: (state, action) => {
      state.cypressModal = !state.cypressModal;
    },
  },
});

export const cypressModalState = (state) => state.capabilities.cypressModal;
export const {
  setBrowsers,
  setDevices,
  setManualTimeout,
  setIdleTimeout,
  setInputData,
  setLogs,
  setVideo,
  setProxy,
  setSelectedData,
  toggleCypressModal,
} = capabilitiesSlice.actions;
export default capabilitiesSlice.reducer;
