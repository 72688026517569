/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/cloudify/background.jpg";
import { AUTHURL } from "utils/constants";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/appSlice";


function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState({});

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendResetLink = async () => {
    const errors = {}; 
   setEmailError(errors); 
    try {
      setLoading(true);
      if (email === "") {
        errors.email = "Email is required";
      } else if (!validateEmail(email)) {
        errors.email = "Please enter a valid email address";
      }
      if (Object.keys(errors).length > 0) {
        setEmailError(errors);
        setLoading(false);
        return;
      }
      const response= await axios.post(`${AUTHURL}//user/email-password-reset-link`, {
        email: email
    })
      console.log(response.data , "response");
      dispatch(
        setSnackbar({
          open: true,
          type: "success",
          title: response?.status,
          content:  response?.data?.message,
        })
    )
      navigate("/sign-in");
    } catch (error) {
      console.error("Error sending reset link:", error);
      dispatch(
        setSnackbar({
          open: true,
          type: "warning",
          title: error?.response?.statusText,
          content: error.response.data ? error.response.data.message : error.message,
        })
    )} finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError({});
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendResetLink();
    }
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox py={8} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                id="email"
                fullWidth
                value={email}
                // value={credentials.email}
                onChange={handleEmailChange}
              onKeyPress={handleKeyPress}
                // onChange={changeCredentials}
              />
              {emailError.email && (
                <MDTypography
                  variant="caption"
                  style={{
                    color: "red",
                    fontWeight: "400",
                    marginTop: "5px",
                    fontSize: "12px",
                    display: "inline",
                  }}
                >
                  {emailError.email}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={loading}
                onClick={sendResetLink}
                // onClick={signIn}
              >
                {loading ? "loading..." : "Reset"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
