const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  open: false,
  rowsSelected: [],
  deletedRows: [],
  deleteSessions: [],
  deleteTunnels: [],
};

const deleteSessions = createSlice({
  name: "deleteSessions",
  initialState: initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setRowsSelected: (state, action) => {
      state.rowsSelected = action.payload;
    },
    setDeletedRows: (state, action) => {
      state.deletedRows = action.payload;
    },
    setDeleteSessions: (state, action) => {
      state.deleteSessions = action.payload;
    },
    setDeleteTunnels: (state, action) => {
      state.deleteTunnels = action.payload;
    },
  },
});

export const { setOpen, setRowsSelected, setDeletedRows, setDeleteSessions, setDeleteTunnels } =
  deleteSessions.actions;
export default deleteSessions.reducer;
