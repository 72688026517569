import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

const MuiTooltip = ({ title, placeholder, content, key }) => {
  const dark = useSelector((state) => {
    return state.app.dark;
  });
  return (
    <Tooltip
      title={title}
      key={key}
      placeholder={placeholder}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: "12px",
            fontWeight: "bold",
            backgroundColor: dark ? "#fff" : "linear-gradient(195deg, #42424a, #191919)",
            color: dark ? "#000000 !important" : "white",
          },
        },
      }}
    >
      {content}
    </Tooltip>
  );
};

export default MuiTooltip;
