import axios from "axios";
import { FLAVOUR2, AUTH_URL, authRoutes } from "./constants";

const getLocalAccessToken = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  return accessToken;
};

const getLocalRefreshToken = () => {
  const refreshToken = window.localStorage.getItem("refreshToken");
  return refreshToken;
};

const customAxios = axios.create({
  // baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

if (authRoutes === "true" || FLAVOUR2 == "false") {
  customAxios.interceptors.request.use(
    (config) => {
      const token = getLocalAccessToken();
      if (token) {
        config.headers["x-access-token"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

customAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      // debugger;
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await refreshToken();
          const access_token = rs.data.data.jwt;
          window.localStorage.setItem("accessToken", access_token);
          customAxios.defaults.headers.common["x-access-token"] = access_token;
          return customAxios(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

const refreshToken = () => {
  return axios
    .post(`${AUTH_URL}/refresh-token`, {
      refresh_token: getLocalRefreshToken(),
      redirect_uri: `${window.location.origin}/auth`,
    })
    .catch((err) => {
      localStorage.clear();
      window.location.href = "/";
    });
};

export default customAxios;
