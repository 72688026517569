const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
import axios from "utils/axios-interceptor";
import { API_URL } from "utils/constants";

const initialState = {
  tunnelSessions: [],
  downloadModalOpen: false,
  instructionModalOpen: false,
  osSystem: "Windows",
  loading: false,
  sort_dict_tunnel: {
    created_at: "desc",
  },
  filter_dict: {
    status: "active",
  },
  dialog: {
    openTunnel: false,
    tunnelData: {},
  },
  tunnelViewLogsData: [],
  rowsPerPageTunnel: 15,
};

export const fetchTunnelData = createAsyncThunk("fetchTunnelData", async (data) => {
  // Define the string
  var string = `${localStorage.getItem("username")}:${localStorage.getItem("automation_token")}`;

  // Encode the String
  var encodedString = btoa(string);
  let payload = {
    page_size: data.page_size,
    page_no: data.page_no,
    filter_dict: data.filter_dict,
    sort_dict: data.sort_dict,
  };
  if (data?.search && data?.search !== "") payload["search"] = data.search;
  const resp = await axios.post(
    `${API_URL}/tunnel/list-connection`,
    { ...payload },
    {
      headers: {
        Authorization: `Basic ${encodedString}`,
      },
    }
  );
  return resp;
});

const tunnelSlice = createSlice({
  name: "tunnel",
  initialState,
  reducers: {
    setDownloadModalOpen: (state, action) => {
      state.downloadModalOpen = action.payload;
    },
    setInstructionModalOpen: (state, action) => {
      state.instructionModalOpen = action.payload;
    },
    setOsSystem: (state, action) => {
      state.osSystem = action.payload;
    },
    changeRowsPerPageTunnel: (state, action) => {
      state.tunnelSessions = [];
      state.rowsPerPageTunnel = action.payload;
    },
    changeSortDictTunnel: (state, action) => {
      state.sort_dict_tunnel = action.payload;
    },
    changeFilter: (state, action) => {
      state.filter_dict = action.payload;
    },
    setDialogTunnel: (state, action) => {
      state.dialog.openTunnel = action.payload.openTunnel;
      state.dialog.tunnelData = action.payload.tunnelData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTunnelData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTunnelData.fulfilled, (state, action) => {
      state.loading = false;
      state.tunnelSessions = action.payload.data.data.records;
    });
    builder.addCase(fetchTunnelData.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setDownloadModalOpen,
  setInstructionModalOpen,
  setOsSystem,
  setDialogTunnel,
  changeRowsPerPageTunnel,
  changePageNumberTunnel,
  changeSortDictTunnel,
} = tunnelSlice.actions;
export default tunnelSlice.reducer;
