import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { COGNITO_LOGIN_URL, FLAVOUR2, DOMAIN } from "utils/constants";
import { useDispatch } from "react-redux";
import { signout } from "store/appSlice";
import { authRoutes } from "utils/constants";

// Private routing
const RequireAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  if (FLAVOUR2 === "false") {
    let token = localStorage.getItem("accessToken");
    let decoded;
    if (token !== null) decoded = jwt_decode(token);
    let latestDate = new Date();

    if (decoded !== undefined && decoded.exp * 1000 < latestDate.getTime()) {
      dispatch(signout());
      return <Navigate to="/sign-in" state={{ from: location }} />;
    }

    if (
      (decoded !== undefined && decoded.exp * 1000 < latestDate.getTime()) ||
      localStorage.getItem("accessToken") === null
    ) {
      return <Navigate to="/sign-in" state={{ from: location }} />;
    } else return <Outlet />;
  } else if (authRoutes === "true") {
    let token = localStorage.getItem("accessToken");
    let decoded;
    if (token !== null) decoded = jwt_decode(token);
    let latestDate = new Date();

    if (decoded !== undefined && decoded.exp * 1000 < latestDate.getTime()) {
      dispatch(signout());
      return <Navigate to="/sign-in" state={{ from: location }} />;
    }

    if (
      (decoded !== undefined && decoded.exp * 1000 < latestDate.getTime()) ||
      localStorage.getItem("accessToken") === null
    ) {
      return <Navigate to="/sign-in" state={{ from: location }} />;
    }

    return <Outlet />;
  } else return <Outlet />;
};

export default RequireAuth;
