const protocol = window.location.protocol === "http:" ? ["http", "ws"] : ["https", "wss"];
export const SOCKET_URL_DATA = `${window.location.origin.replace(
  protocol[0],
  protocol[1]
)}/ws/configure-org`;
let apiUrl = `${window.location.origin}/`;
// const apiUrl = "http://localhost:5000/";
// const apiUrl = "http://localhost:8080";
export const authRoutes = "false";
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const API_URL = apiUrl;
export const AUTHURL = apiUrl;
export const SITEKEY = "6LcS3WEqAAAAABNvyArp9V6-xIyWMOspX8SXkk-b";
export const SOCKET_URL = window.location.origin.replace(protocol[0], protocol[1]);
export const COPY_URL = `${protocol[0]}://${window.location.hostname}/wd/hub`;
export const DELETE_POD_URL = window.location.origin;
export const TUNNEL_URL = window.location.origin.concat("/tunnelbe");
export const ENV = process.env.REACT_APP_ENV;
export const COGNITO_LOGIN_URL = process.env.REACT_APP_COGNITO_LOGIN_URL;
// export const COGNITO_LOGIN_URL =
//   "https://stage-cloudifytests.auth.us-east-1.amazoncognito.com/login?client_id=3jrf8mkj4j49k8ot8m9po121c9&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://stg.cloudifytests.io/auth";
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const SUBSCRIPTION_URL = process.env.REACT_APP_SUBSCRIPTION_URL;
// export const SUBSCRIPTION_URL = "http://localhost:5005";
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const LOGS_URL = window.location.origin.concat("/logsbe");
export const RECONNECT_SOCKET_CODE = 1006;
export const DELETE_ALL_SESSIONS = window.location.origin + "/smdelete";
export const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

// export const FLAVOUR2 = "false";
export const FLAVOUR2 = process.env.REACT_APP_FLAVOUR2;

export const chrome =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/chrome.svg";
export const firefox =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/firefox.svg";
export const opera =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/opera.svg";
export const edge =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/microsoft_edge.svg";
export const safari =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/safari.svg";
export const appium =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/appium.svg";
export const android =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/android.svg";
export const cypress =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/cypress.jpeg";
export const cloudifyLight =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/light.svg";
export const cloudifyDark =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/cloudifytests-white-bg.svg";
export const BUILD_LOADER =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/build-2.gif";
export const ERROR_LOADER =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/error-2.gif";

export const correct_icon =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/correct.png";
export const uncorrect_icon =
  "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/uncorrect.png";

export const BROWSER_API = "https://raw.githubusercontent.com/CloudBrowsers/public-assets/main";

export const graphOptions = {
  // responsive: true,
  maintainAspectRatio: false,
  plugins: {
    animation: {
      y: { from: 500 },
    },
    legend: {
      position: "bottom",
      display: true,
    },
    tooltip: {
      // enabled: false,
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Bar Chart",
    // },
  },
  // categoryPercentage: 0.5,
  // barPercentage: 0.8,
  // barThickness: 40, // number (pixels) or 'flex'
  // maxBarThickness: 40, // number (pixels)
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Date",
        color: "black",
        font: {
          size: 16,
        },
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "No. Of Tests",
        color: "black",
        font: {
          size: 16,
        },
      },
    },
  },
};

const session = {
  pod_name: "nckdsds",
  pod_id: "ncjds",
  sessionId: "dcncdhb",
};

export const dummyTabledata = [
  {
    browser: ["cypress", "10.0"],
    browser_version: "10.0",
    automation_type: "automation",
    org_name: "dwipoctober",
    device_name: null,
    session_timeout: 7200,
    idle_session_timeout: 7200,
    build_name: "build-appium is here with a long name",
    proxy_name: null,
    enable_proxy: false,
    id: "5b35b171-9d80-4dfb-afbf-61d45412d36f",
    test_name: "test-appium",
    status: "running",
    status_message: "Request is being processed.",
    enable_video: true,
    enable_logs: true,
    duration: null,
    screen_resolution: "1920x1080",
    session_automation_type: "cypress",
    created_at: 1700055050.76643,
    user_name: "dwipt",
    toolbar: {
      name: "firefox-browser-jff8h",
      ip: "10.42.3.116",
      session_id: "65f3962bedce43749d1463a1d45cfdcb",
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 2",
    status: "timeout",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "selenium",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 3",
    status: "running",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "playwright",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "108.0"],
    test_name: "tanmay 4",
    status: "running",
    enable_video: "false",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "manual",
    browser_version: "108.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 5",
    status: "aborted",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "playwright",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 6",
    status: "running",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "manual",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 7",
    status: "running",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "playwright",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 8",
    status: "running",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "playwright",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 9",
    status: "error",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "playwright",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 10",
    status: "running",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "playwright",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 11",
    status: "running",
    enable_video: "true",
    enable_logs: "false",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "playwright",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
  {
    id: "8e682014-dfbe-4e02-9ec8-bb77f1073f48",
    browser: ["chrome", "106.0"],
    test_name: "tanmay 12",
    status: "running",
    enable_video: "true",
    enable_logs: "true",
    duration: "10 min",
    screen_resolution: "1000*1000",
    created_at: 1687244706.841088,
    build_name: "test",
    session_automation_type: "playwright",
    browser_version: "106.0",
    toolbar: {
      name: session.pod_name,
      ip: session.pod_ip,
      session_id: session.session_id,
    },
  },
];
