/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/cloudify/background.jpg";
import { AUTHURL } from "utils/constants";
import { DOMAIN } from "utils/constants";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/appSlice";
import jwt_decode from "jwt-decode";

function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; 
  const userToken = queryParams.get("user_token");
  const token = queryParams.get("token");
  let accessToken = localStorage.getItem("accessToken");
  let decoded;
  try {
    if (accessToken  !== null) decoded = jwt_decode(accessToken);
  } catch (error) {
    console.error("Failed to decode token", error);
  }
  // if (token !== null) decoded = jwt_decode(accessToken);

  
  let latestDate = new Date();

  const changeCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
    setErrors({ ...errors, [e.target.id]: "" });
  };

  const validateForm = () => {
    let formErrors = {};
    if (credentials.password === "") {
      formErrors.password = "Password is required";
    } else if (!regex.test(credentials.password)) {
      formErrors.password =
        "Password must be at least 8 characters";
    }
    if (credentials.confirmPassword === "") {
      formErrors.confirmPassword = "Confirm Password is required";
    } else if (credentials.password !== credentials.confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };


  const signIn = async () => {
    if (!validateForm()) return;
    try {
      setLoading(true);
      let newCredsData = {
        password: credentials.password,
        confirm_password: credentials.confirmPassword,
      };
      if (userToken) newCredsData.user_token = userToken;
      if (token) newCredsData.token = token;
      const resp = await axios.post(`${AUTHURL}//user/reset-password`, newCredsData);
      console.log(resp.data.data);
      let info = resp.data.data;
      dispatch(
        setSnackbar({
          open: true,
          type: "success",
          title: "Successfully changed password",
          content: "Please login with your new password",
        })
      );
      navigate("/sign-in", { replace: true });
      // window.location.replace("/sign-in");
    } catch (error) {
      dispatch(
        setSnackbar({
          open: true,
          type: "warning",
          title: error?.response?.statusText,
          content: error.response.data ? error.response.data.message : error.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const passwordEnteredFunction = (e) => {
    if (e.key == "Enter") {
      signIn();
    }
  };

  if (decoded && decoded.exp * 1000 > latestDate.getTime()) return <Navigate to={"/"} />;

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Set Password
          </MDTypography>
        </MDBox>
        <MDBox py={8} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="New-Password"
                id="password"
                fullWidth
                value={credentials.password}
                onChange={changeCredentials}
              />
              {errors.password && (
                <MDTypography
                  variant="caption"
                  style={{
                    color: "red",
                    fontWeight: "400",
                    marginTop: "5px",
                    fontSize: "12px",
                    display: "inline",
                  }}
                >
                  {errors.password}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm-Password"
                id="confirmPassword"
                fullWidth
                value={credentials.confirmPassword}
                onChange={changeCredentials}
                onKeyDown={passwordEnteredFunction}
              />
                            {errors.confirmPassword && (
                <MDTypography
                  variant="caption"
                  style={{
                    color: "red",
                    fontWeight: "400",
                    marginTop: "5px",
                    fontSize: "12px",
                    display: "inline",
                  }}
                >
                  {errors.confirmPassword}
                </MDTypography>
              )}

            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={loading}
                onClick={signIn}
              >
                {loading ? "loading..." : "Set Password"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
