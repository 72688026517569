import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
} from "@mui/material";
import axios from "utils/axios-interceptor";
import { API_URL } from "utils/constants";
import "../table.css";
import { useDispatch, useSelector } from "react-redux";
// import { setResetFilterSessions } from "store/sessionsSlice";
import { setResetFilterHistorySessions } from "store/historySessionSlice";
import { searchTextBuildName } from "utils/helper";
import { fetchUserSettingsData } from "store/userSettingsSlice";

const BuildSearchDropdown = ({ filterList, index, column, data, onChange, page }) => {
  const dispatch = useDispatch();
  let recentData;
  const [searchText, setSearchText] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [apiFilterList, setApiFilterList] = useState([]);
  const [showData, setShowData] = useState();

  const resetFilterSessions = useSelector((store) => {
    return store.sessions.resetFilterSessions;
  });
  const resetFilterHistorySessions = useSelector((store) => {
    return store.historySession.resetFilterHistorySessions;
  });
  const userNameFilter = useSelector((store) => {
    return store.userSettingsSlice.userNameFilter;
  });

  const handleShowMore = (e) => {
    e.stopPropagation();
    setShowMore(true);
  };

  useEffect(() => {
    if (column?.name === "build_name") {
      filterApi();
    } else if (column?.name === "user_name") {
      dispatch(fetchUserSettingsData());
    }
    if (resetFilterSessions || resetFilterHistorySessions) {
      setSearchText("");
      setShowMore(false);
      dispatch(page === "sessions" ? console.log(false) : setResetFilterHistorySessions(false));
    }
  }, [showMore, searchText, resetFilterSessions, resetFilterHistorySessions]);

  const filterApi = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/webdriver_session/sessions/history/get-filter-data?page_type=history&filter_type=${
          showMore ? "all" : "recent"
        }&search=${searchText}`
      );
      setApiFilterList(response.data.data.build_names);
      setShowData(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };
  const displaySessionOptions = data[9].filter((option) => searchTextBuildName(option, searchText));
  if (searchText.length > 0) {
    recentData = data[9].filter((option) => searchTextBuildName(option, searchText));
  } else {
    recentData = data[9].slice(0, 5);
  }
  const displayHistorySessionOptions = apiFilterList.filter((option) =>
    searchTextBuildName(option, searchText)
  );

  const renderMenuItems = () => {
    const options =
      page === "sessions"
        ? showMore
          ? displaySessionOptions
          : recentData
        : column.name === "user_name"
        ? userNameFilter
        : displayHistorySessionOptions;
    return options.map((item) => (
      <MenuItem key={item} value={item} className="text-transform-none">
        <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        <ListItemText primary={item} />
      </MenuItem>
    ));
  };

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor="select-multiple-chip">
        {column.name === "user_name" ? "Created By" : "Build Name"}
      </InputLabel>
      <Select
        MenuProps={{ autoFocus: false }}
        multiple
        value={filterList[index]}
        renderValue={(selected) => selected.join(", ")}
        onChange={(event) => {
          filterList[index] = event.target.value;
          onChange(filterList[index], index, column);
        }}
      >
        {column.name === "build_name" && (
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {renderMenuItems()}
        {!showMore && showData && showData >= 6 && (
          <div>
            <Button className="showMoreBtn" onClick={handleShowMore} name="btn">
              Show More
            </Button>
          </div>
        )}
      </Select>
    </FormControl>
  );
};

export default BuildSearchDropdown;
