import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import capabilitiesSlice from "./capabilitiesSlice";
import dashboardSlice from "./dashboardSlice";
import historySessionSlice from "./historySessionSlice";
import sessionsSlice from "./sessionsSlice";
import tunnelSlice from "./tunnelSlice";
import tunnelLogsSlice from "./tunnelLogsSlice";
import historyTunnelSlice from "./historyTunnelSlice";
import userSettingsSlice from "./userSettingsSlice";
import deleteSessions from "./deleteSessions";
import buildSlice from "./buildSlice";
import buildSessionsSlice from "./buildSessionsSlice";

const store = configureStore({
  reducer: {
    app: appSlice,
    dashboard: dashboardSlice,
    sessions: sessionsSlice,
    historySession: historySessionSlice,
    capabilities: capabilitiesSlice,
    tunnelSlice: tunnelSlice,
    tunnelLogsSlice: tunnelLogsSlice,
    historyTunnelSlice: historyTunnelSlice,
    userSettingsSlice: userSettingsSlice,
    deleteSessions: deleteSessions,
    buildSlice: buildSlice,
    buildSessionsSlice: buildSessionsSlice,
  },
});

export default store;
