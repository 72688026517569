/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/cloudify/background.jpg";
import { AUTHURL } from "utils/constants";
import { DOMAIN } from "utils/constants";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/appSlice";
import jwt_decode from "jwt-decode";

function Basic() {
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  let token = localStorage.getItem("accessToken");
  let decoded;
  if (token !== null) decoded = jwt_decode(token);
  let latestDate = new Date();

  const setCookies = (cookieName, cookieValue) => {
    document.cookie = `${cookieName}=${cookieValue};domain=${DOMAIN};path=/`;
  };

  const changeCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  };

  const signIn = async () => {
    try {
      setLoading(true);
      if (credentials.email === "" || credentials.password === "")
        throw { message: "All params required", response: { statusText: "Something went wrong" } };

      const resp = await axios.post(`${AUTHURL}/user/login`, credentials);
      console.log(resp.data.data);
      let info = resp.data.data;
      localStorage.setItem("accessToken", info.jwt);
      localStorage.setItem("refreshToken", info.refresh_token);
      localStorage.setItem("username", info.username);
      localStorage.setItem("role", info.role);
      localStorage.setItem("automation_token", info.automation_token);
      localStorage.setItem("user_id", info.user_id);
      setCookies("accessToken", info.jwt);
      setCookies("refreshToken", info.refresh_token);
      setCookies("username", info.username);
      setCookies("role", info.role);
      setCookies("automation_token", info.automation_token);
      setCookies("user_id", info.user_id);
      // navigate("/", { replace: true });
      window.location.replace("/");
    } catch (error) {
      console.log(error);
      dispatch(
        setSnackbar({
          open: true,
          type: "warning",
          title: error?.response?.statusText,
          content: error.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const passwordEnteredFunction = (e) => {
    if (e.key == "Enter") {
      signIn();
    }
  };

  if (decoded && decoded.exp * 1000 > latestDate.getTime()) return <Navigate to={"/"} />;

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Sign up
          </MDTypography>
        </MDBox>
        <MDBox py={8} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                id="email"
                fullWidth
                value={credentials.email}
                onChange={changeCredentials}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                id="password"
                fullWidth
                value={credentials.password}
                onChange={changeCredentials}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm Password"
                id="confirmPassword"
                fullWidth
                value={credentials.confirmPassword}
                onChange={changeCredentials}
                onKeyDown={passwordEnteredFunction}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={loading}
                onClick={signIn}
              >
                {loading ? "loading..." : "sign up"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
